<template>
  <div>
    <b-card
      title="Data Hutang Kru Belum Lunas"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addHutangSupirSidebar = true"
            >
              + Tambah Data Hutang Kru
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Jumlah -->
        <template #cell(jumlah)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <span class="text-capitalize">{{ data.value }}</span>
        </template>

        <!-- Column: Supir / Kernet -->
        <template #cell(kru)="data">
          <span class="text-info">{{ data.item.role === 'supir' ? data.item.supir.nama : data.item.kernet.nama }}</span>
        </template>

        <!-- Column: Sisa Hutang -->
        <template #cell(sisa_hutang)="data">
          <span class="text-danger">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Tanggal Hutang -->
        <template #cell(tanggal_hutang)="data">
          <span class="text-primary">{{ readableDate(data.value) }}</span>
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <!-- <b-button
                :id="`HutangSupir-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editHutangSupir(data.item, true)"
              >
                Edit
              </b-button>
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`HutangSupir-row-${data.item.id}-edit-icon`"
              />

              <b-button
                :id="`HutangSupir-row-${data.item.id}-hapus-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button>
              <b-tooltip
                title="Hapus"
                :target="`HutangSupir-row-${data.item.id}-hapus-icon`"
              /> -->

              <b-button
                :id="`HutangSupir-row-${data.item.id}-bayar-icon`"
                size="sm"
                variant="info"
                @click="bayarData(data.item)"
              >
                Bayar
              </b-button>
              <b-button
                :id="`PembayaranSupir-row-${data.item.id}-preview-icon`"
                size="sm"
                variant="warning"
                @click="previewCetak(data.item)"
              >
                Cetak
              </b-button>
              <b-tooltip
                title="Cetak"
                class="cursor-pointer"
                :target="`PembayaranSupir-row-${data.item.id}-preview-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalHutangSupir"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-card
      title="Data Hutang Kru Lunas"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQueryLunas"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rowsLunas"
        responsive
        :fields="columnsLunas"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPageLunas"
        :filter="searchQueryLunas"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Jumlah -->
        <template #cell(jumlah)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <span class="text-capitalize">{{ data.value }}</span>
        </template>

        <!-- Column: Supir / Kernet -->
        <template #cell(kru)="data">
          <span class="text-info">{{ data.item.role === 'supir' ? data.item.supir.nama : data.item.kernet.nama }}</span>
        </template>

        <!-- Column: Tanggal Hutang -->
        <template #cell(tanggal_hutang)="data">
          <span class="text-primary">{{ readableDate(data.value) }}</span>
        </template>

        <!-- Column: Image -->
        <template #cell(foto_nota)="data">
          <b-img
            :src="require(`@/assets/images/pages/${data.value}`)"
            height="125"
            alt="Responsive image"
          />
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`PembayaranSupir-row-${data.item.id}-preview-icon`"
                size="sm"
                variant="info"
                @click="previewCetak(data.item)"
              >
                Cetak
              </b-button>
              <b-tooltip
                title="Cetak"
                class="cursor-pointer"
                :target="`PembayaranSupir-row-${data.item.id}-preview-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageLunas"
              :total-rows="totalHutangSupirLunas"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="bayarHutangSupirModal"
      title="Bayar Hutang"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer
        ref="simpleRules"
        class="px-2"
      >
        <b-form>
          <b-form-group
            label="Keperluan Hutang"
            label-for="keperluan-hutang-supir"
          >
            <b-form-input
              id="keperluan-hutang-supir"
              v-model="dataHutangSupirAkanDibayar.keperluan_hutang"
              disabled
              type="text"
              required
            />
          </b-form-group>
          <b-form-group
            label="Keterangan"
            label-for="keterangan-hutang-supir"
          >
            <b-form-input
              id="keterangan-hutang-supir"
              v-model="dataHutangSupirAkanDibayar.keterangan"
              disabled
              type="text"
              required
            />
          </b-form-group>
          <span class="font-weight-bold">
            Sisa Hutang: {{ dataHutangSupirAkanDibayar.sisa_hutang | formatRupiah }}
          </span>
          <b-form-group
            class="mt-1"
            label="Jumlah Bayar Hutang"
            label-for="jumlah-hutang"
          >
            <b-form-input
              id="jumlah-hutang"
              v-model="dataHutangSupirAkanDibayar.jumlah_pembayaran"
              type="number"
              :max="dataHutangSupirAkanDibayar.sisa_hutang"
              required
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <AddHutangSupirContent
      v-model="addHutangSupirSidebar"
      :hutang-supir="dataEditHutangSupir"
      @hutang-supir-sidebar-change="editHutangSupir"
      @submit-data-hutang-supir="submitData"
    />

    <b-modal
      id="modal-cetal"
      v-model="modalCetak"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      title="Hutang Supir Invoice"
    >
      <preview-cetak
        :hutang="dataHutangPreview"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BTooltip,
  BPagination, BButtonGroup, BImg, BModal, BFormGroup,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import AddHutangSupirContent from './Add.vue'
import previewCetak from './Preview.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BButtonGroup,
    BImg,
    BModal,
    BTooltip,
    BFormGroup,

    AddHutangSupirContent,
    previewCetak,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Posisi',
          key: 'role',
        },
        {
          label: 'Kru',
          key: 'kru',
        },
        {
          label: 'Keperluan',
          key: 'keperluan_hutang',
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
        },
        {
          label: 'Jumlah',
          key: 'jumlah',
        },
        {
          label: 'Sisa Hutang',
          key: 'sisa_hutang',
        },
        {
          label: 'Tanggal Hutang',
          key: 'tanggal_hutang',
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      columnsLunas: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Posisi',
          key: 'role',
        },
        {
          label: 'Kru',
          key: 'kru',
        },
        {
          label: 'Keperluan',
          key: 'keperluan_hutang',
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
        },
        {
          label: 'Jumlah',
          key: 'jumlah',
        },
        {
          label: 'Tanggal Hutang',
          key: 'tanggal_hutang',
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      perPage: 5,
      currentPage: 1,
      currentPageLunas: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      searchQueryLunas: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      rowsData: [],
      rowsDataLunas: [],
      searchTerm: '',
      statusOptions: [
        'Aktif',
        'Tidak Aktif',
      ],
      bayarHutangSupirModal: false,
      addHutangSupirSidebar: false,
      dataEditHutangSupir: {
        id: '1',
        supir: null,
        keterangan: null,
        jumlah: null,
        tanggal: null,
      },
      dataHutangSupirAkanDibayar: {
        id: null,
        sisa_htuang: 0,
        jumlah_pembayaran: 0,
      },
      modalCetak: false,
      dataHutangPreview: {},
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      return this.rowsData.filter(data => data.status === 'belum_lunas')
    },
    rowsLunas() {
      return this.rowsData.filter(data => data.status === 'lunas')
    },
    totalHutangSupir() {
      return this.rowsData.filter(data => data.status === 'belum_lunas').length
    },
    totalHutangSupirLunas() {
      return this.rowsData.filter(data => data.status === 'lunas').length
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // eslint-disable-next-line no-return-assign
      this.$http.get('/hutang-kru').then(response => {
        this.rowsData = response.data.data
      })
    },
    editHutangSupir(data, value) {
      this.dataEditHutangSupir = data
      this.addHutangSupirSidebar = value
    },
    submitData(data) {
      if (data.id === null) {
        const formData = new FormData()
        formData.append('id_kru', data.supir)
        formData.append('keperluan_hutang', data.keperluan)
        formData.append('role', data.role)
        formData.append('keterangan', data.keterangan)
        formData.append('jumlah', data.jumlah)
        formData.append('status', 'belum_lunas')

        this.$http.post('/hutang-kru', formData).then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })

          this.getData()
        })
      } else {
        const formData = new FormData()
        formData.append('id_kru', data.supir)
        formData.append('keperluan_hutang', data.keperluan)
        formData.append('role', data.role)
        formData.append('keterangan', data.keterangan)
        formData.append('jumlah', data.jumlah)
        formData.append('status', 'belum_lunas')

        this.$http.post(`/hutangSupir-update/${data.id}`, formData).then(response => {
          this.getData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })
        })
      }
      this.addHutangSupirSidebar = false
    },
    resetModal() {
      this.dataHutangSupirAkanDibayar = {
        id: null,
        jumlah: 0,
        sisa_hutang: 0,
        jumlah_pembayaran: 0,
        keterangan: null,
        keperluan_hutang: null,
      }
    },
    bayarData(data) {
      this.dataHutangSupirAkanDibayar = data
      this.bayarHutangSupirModal = true
      // this.$swal({
      //   title: 'Anda yakin ingin mengubah status menjadi sudah terbayar?',
      //   text: "You won't be able to revert this!",
      //   icon: 'info',
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes!',
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //     cancelButton: 'btn btn-outline-danger ml-1',
      //   },
      //   buttonsStyling: false,
      // }).then(result => {
      //   if (result.value) {
      //     this.rowsData = this.rowsData.filter(item => item.id !== id)
      //     this.$swal({
      //       icon: 'success',
      //       title: 'Success!',
      //       text: 'Data sudah diubah menjadi terbayar.',
      //       customClass: {
      //         confirmButton: 'btn btn-success',
      //       },
      //     })
      //   }
      // })
    },
    handleOk() {
      const data = {
        jumlah_pembayaran: this.dataHutangSupirAkanDibayar.jumlah_pembayaran,
      }
      this.$http.post(`hutang-kru/pembayaran/${this.dataHutangSupirAkanDibayar.id}`, data).then(response => {
        if (response.data.status === true) {
          this.$swal({
            icon: 'success',
            title: 'Berhasil Dibayar!',
            text: response.data.message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

          this.getData()
        }
      })
    },
    readableDate(data) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric',
      }
      return new Date(data).toLocaleDateString(undefined, options)
    },
    previewCetak(data) {
      this.modalCetak = true
      this.dataHutangPreview = data
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
