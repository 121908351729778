<template>
  <div>
    <b-sidebar
      id="add-kas-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addHutangSupirSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ hutangSupirLocal.id > 0 ? 'Edit Hutang Kru' : 'Add Hutang Kru' }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Posisi"
                  label-for="role-hutang-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Posisi"
                    rules="required"
                  >
                    <b-form-select
                      id="role-hutang-supir"
                      v-model="hutangSupirLocal.role"
                      :options="optionsRole"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Posisi"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Supir"
                  label-for="supir-hutang-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Supir"
                    rules="required"
                  >
                    <b-form-select
                      id="supir-hutang-supir"
                      v-model="hutangSupirLocal.supir"
                      :disabled="hutangSupirLocal.role === null"
                      :options="optionsSupir"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Status Supir"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Keperluan Hutang"
                  label-for="keperluan-hutang-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Keperluan Hutang"
                    rules="required"
                  >
                    <b-form-input
                      id="keperluan-hutang-supir"
                      v-model="hutangSupirLocal.keperluan"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Keperluan Hutang"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Keterangan"
                  label-for="keterangan-hutang-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Keterangan"
                    rules="required"
                  >
                    <b-form-input
                      id="keterangan-hutang-supir"
                      v-model="hutangSupirLocal.keterangan"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Keterangan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jumlah"
                  label-for="jumlah-hutang-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah"
                    rules="required"
                  >
                    <b-form-input
                      id="jumlah-hutang-supir"
                      v-model="hutangSupirLocal.jumlah"
                      type="number"
                      :disabled="hutangSupirLocal.id > 0 ? true : false"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jumlah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormSelect,
  },
  model: {
    prop: 'addHutangSupirSidebar',
  },
  props: {
    addHutangSupirSidebar: {
      type: Boolean,
      required: true,
    },
    hutangSupir: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      hutangSupirLocal: {
        id: null,
        supir: null,
        keperluan: null,
        keterangan: null,
        jumlah: null,
        role: null,
      },
      optionsSupir: [],
      optionsBis: [],
      optionsRole: [
        { value: 'supir', text: 'Supir' },
        { value: 'kernet', text: 'Kernet' },
      ],
    }
  },
  watch: {
    hutangSupir() {
      this.resetTaskLocal()
    },
    // eslint-disable-next-line func-names
    'hutangSupirLocal.role': function (newValue) {
      if (newValue === 'supir') {
        this.getDataSupir()
      } else {
        this.getDataKernet()
      }
    },
  },
  methods: {
    getDataSupir() {
      this.$http.get('/supir-data').then(response => {
        const dataOptions = []
        // eslint-disable-next-line array-callback-return
        response.data.data.map(data => {
          const dataSupir = {
            value: data.id,
            text: data.nama,
          }
          dataOptions.push(dataSupir)
          this.optionsSupir = dataOptions
        })
      })
    },
    getDataKernet() {
      this.$http.get('/kernet-data').then(response => {
        const dataOptions = []
        // eslint-disable-next-line array-callback-return
        response.data.data.map(data => {
          const dataSupir = {
            value: data.id,
            text: data.nama,
          }
          dataOptions.push(dataSupir)
          this.optionsSupir = dataOptions
        })
      })
    },
    resetTaskLocal() {
      this.hutangSupirLocal = {
        id: this.hutangSupir.id,
        supir: this.hutangSupir.supir.id,
        keperluan: this.hutangSupir.keperluan_hutang,
        keterangan: this.hutangSupir.keterangan,
        jumlah: this.hutangSupir.jumlah,
      }
    },
    changeValue() {
      const data = {
        id: null,
        supir: null,
        keperluan: null,
        keterangan: null,
        jumlah: null,
      }
      this.$emit('hutang-supir-sidebar-change', data, false)
    },
    submit() {
      if (this.hutangSupirLocal.id > 0) {
        this.$emit('submit-data-hutang-supir', this.hutangSupirLocal)
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$emit('submit-data-hutang-supir', this.hutangSupirLocal)
          }
        })
      }
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
