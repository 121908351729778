var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-kas-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addHutangSupirSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.hutangSupirLocal.id > 0 ? 'Edit Hutang Kru' : 'Add Hutang Kru')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Posisi","label-for":"role-hutang-supir"}},[_c('validation-provider',{attrs:{"name":"Posisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"role-hutang-supir","options":_vm.optionsRole,"state":errors.length > 0 ? false:null,"placeholder":"Posisi"},model:{value:(_vm.hutangSupirLocal.role),callback:function ($$v) {_vm.$set(_vm.hutangSupirLocal, "role", $$v)},expression:"hutangSupirLocal.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Supir","label-for":"supir-hutang-supir"}},[_c('validation-provider',{attrs:{"name":"Supir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"supir-hutang-supir","disabled":_vm.hutangSupirLocal.role === null,"options":_vm.optionsSupir,"state":errors.length > 0 ? false:null,"placeholder":"Status Supir"},model:{value:(_vm.hutangSupirLocal.supir),callback:function ($$v) {_vm.$set(_vm.hutangSupirLocal, "supir", $$v)},expression:"hutangSupirLocal.supir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Keperluan Hutang","label-for":"keperluan-hutang-supir"}},[_c('validation-provider',{attrs:{"name":"Keperluan Hutang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"keperluan-hutang-supir","state":errors.length > 0 ? false:null,"placeholder":"Keperluan Hutang"},model:{value:(_vm.hutangSupirLocal.keperluan),callback:function ($$v) {_vm.$set(_vm.hutangSupirLocal, "keperluan", $$v)},expression:"hutangSupirLocal.keperluan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"keterangan-hutang-supir"}},[_c('validation-provider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"keterangan-hutang-supir","state":errors.length > 0 ? false:null,"placeholder":"Keterangan"},model:{value:(_vm.hutangSupirLocal.keterangan),callback:function ($$v) {_vm.$set(_vm.hutangSupirLocal, "keterangan", $$v)},expression:"hutangSupirLocal.keterangan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jumlah","label-for":"jumlah-hutang-supir"}},[_c('validation-provider',{attrs:{"name":"Jumlah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jumlah-hutang-supir","type":"number","disabled":_vm.hutangSupirLocal.id > 0 ? true : false,"state":errors.length > 0 ? false:null,"placeholder":"Jumlah"},model:{value:(_vm.hutangSupirLocal.jumlah),callback:function ($$v) {_vm.$set(_vm.hutangSupirLocal, "jumlah", $$v)},expression:"hutangSupirLocal.jumlah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }